import { useTranslation } from "react-i18next"

export const useZXCVBNTranslation = () => {
  const { t } = useTranslation()

  const getTranslatedSuggestion = (suggestion: string) => {
    const translationKeys: Record<string, string> = {
      "Use a few words, avoid common phrases": t(
        "components.account.PasswordForm.SuggestionMessages.UseAFewWords"
      ),
      "No need for symbols, digits, or uppercase letters": t(
        "components.account.PasswordForm.SuggestionMessages.NoNeedForSymbols"
      ),
      "Add another word or two. Uncommon words are better.": t(
        "components.account.PasswordForm.SuggestionMessages.AddAnotherWord"
      ),
      "Straight rows of keys are easy to guess": t(
        "components.account.PasswordForm.SuggestionMessages.StraightRows"
      ),
      "Avoid repeated words and characters": t(
        "components.account.PasswordForm.SuggestionMessages.AvoidRepeatedWordsAndCharacters"
      ),
      "Avoid sequences": t(
        "components.account.PasswordForm.SuggestionMessages.AvoidSequences"
      ),
      "Avoid recent years": t(
        "components.account.PasswordForm.SuggestionMessages.AvoidRecentYears"
      ),
      "Avoid years that are associated with you": t(
        "components.account.PasswordForm.SuggestionMessages.AvoidYearsThatAreAssociatedWithYou"
      ),
      "Avoid dates and years that are associated with you": t(
        "components.account.PasswordForm.SuggestionMessages.AvoidDatesAndYearsThatAreAssociatedWithYou"
      ),
      "Capitalization doesn't help very much": t(
        "components.account.PasswordForm.SuggestionMessages.CapitalizationDoesntHelpVeryMuch"
      ),
      "All-uppercase is almost as easy to guess as all-lowercase": t(
        "components.account.PasswordForm.SuggestionMessages.AllUppercaseIsAlmostAsEasyToGuessAsAllLowercase"
      ),
      "Reversed words aren't much harder to guess": t(
        "components.account.PasswordForm.SuggestionMessages.ReversedWordsArentMuchHarderToGuess"
      ),
      "Predictable substitutions like '@' instead of 'a' don't help very much":
        t(
          "components.account.PasswordForm.SuggestionMessages.PredictableSubstitutionsLikeInsteadOfDontHelpVeryMuch"
        ),
      "": t("components.account.PasswordForm.SuggestionMessages.Empty"),
    }

    return translationKeys[suggestion] || ""
  }

  const getTranslatedWarning = (warning: string) => {
    const translationKeys: Record<string, string> = {
      "Straight rows of keys are easy to guess": t(
        "components.account.PasswordForm.WarningMessages.StraightRows"
      ),
      "Short keyboard patterns are easy to guess": t(
        "components.account.PasswordForm.WarningMessages.ShortPatterns"
      ),
      "Use a longer keyboard pattern with more turns": t(
        "components.account.PasswordForm.WarningMessages.LongerPatterns"
      ),
      'Repeats like "aaa" are easy to guess': t(
        "components.account.PasswordForm.WarningMessages.RepeatsLikeAAA"
      ),
      'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
        t(
          "components.account.PasswordForm.WarningMessages.RepeatsLikeABCABCABC"
        ),
      "Sequences like abc or 6543 are easy to guess": t(
        "components.account.PasswordForm.WarningMessages.SequencesLikeABCOR6543"
      ),
      "Recent years are easy to guess": t(
        "components.account.PasswordForm.WarningMessages.RecentYearsAreEasyToGuess"
      ),
      "Dates are often easy to guess": t(
        "components.account.PasswordForm.WarningMessages.DatesAreOftenEasyToGuess"
      ),
      "This is a top-10 common password": t(
        "components.account.PasswordForm.WarningMessages.Top10CommonPassword"
      ),
      "This is a top-100 common password": t(
        "components.account.PasswordForm.WarningMessages.Top100CommonPassword"
      ),
      "This is a very common password": t(
        "components.account.PasswordForm.WarningMessages.VeryCommonPassword"
      ),
      "This is similar to a commonly used password": t(
        "components.account.PasswordForm.WarningMessages.SimilarToCommonPassword"
      ),
      "A word by itself is easy to guess": t(
        "components.account.PasswordForm.WarningMessages.AWordByItselfIsEasyToGuess"
      ),
      "Names and surnames by themselves are easy to guess": t(
        "components.account.PasswordForm.WarningMessages.NamesAndSurnamesByThemselvesAreEasyToGuess"
      ),
      "Common names and surnames are easy to guess": t(
        "components.account.PasswordForm.WarningMessages.CommonNamesAndSurnamesAreEasyToGuess"
      ),
      "": t("components.account.PasswordForm.WarningMessages.Empty"),
    }

    return translationKeys[warning] || ""
  }

  return { getTranslatedSuggestion, getTranslatedWarning }
}
